body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@media print {
  @page {
    margin: 1in;
  }
}
